import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { appendScript, removeScript } from '../../utils/scripts';

const HomePage = () => {
  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>About Us : Pets at Peace by Fox and Weeks</title>
      </Helmet>
      <div style={{ backgroundColor: '#F1F1F1', paddingBottom: '50px' }}>
        <div className="row container center">
          <h1 style={{ margin: '0' }}>About Us</h1>
          {/* <p className="col s12 m12 l8 offset-l2">
                  Pet Tales is an online pet obituary and tribute gateway that is designed for you, the pet parent as a way to honor your pet's life and share their story with others. By sharing a Pet Tale you will not only embrace the memories of your pet but you will create a permanent place for
                  yourself, your family and your friends to return to as you remember your faithful companion that brought so much happiness.
               </p> */}
          {/* <div className="clearfix"></div>
					<Link to="/login" className="btn-large">
						CREATE A PET TALE
					</Link> */}
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <br />

      <div className="row container">
        <div className="col s12 m12 l8 offset-l2">
          <p>
            Pets at Peace by Fox & Weeks is offers pet loss services to Coastal
            Georgia and the South Carolina Low country adhering to the same high
            standards Fox & Weeks Funeral Directors has established in 140 years
            of serving Savannah. Pets at Peace offers 24-hour immediate response
            and dignified pet cremation services to make sure families have a
            chance to say a proper goodbye.
          </p>
          <p>
            We know losing a pet is difficult, because we are pet parents and
            lovers of animals ourselves. To all of the pet parents we have
            served and will serve, we offer our most sincere condolences. If you
            have not already done so, we encourage you to visit the{' '}
            <Link to="/Pet-Tales">Pet Tales</Link> portion of our website, where
            you can create a memorial to honor your pet to be shared with others
            locally and nationally.
          </p>
        </div>
      </div>

      <div className="clearfix"></div>
      <br />
      <div className="row container cursors"></div>

      <div className="clearfix"></div>
      <br />
      <br />
    </div>
  );
};

export default HomePage;
