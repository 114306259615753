import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import { appendScript, removeScript } from '../../utils/scripts';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  useEffect(() => {
    let elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems, {
      accordion: false,
      onOpenStart: function (e) {
        e.querySelector('.material-icons').innerHTML = 'remove';
      },
      onCloseStart: function (e) {
        e.querySelector('.material-icons').innerHTML = 'add';
      },
    });

    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>FAQs : Pets at Peace Savannah</title>
      </Helmet>
      <div style={{ backgroundColor: '#F1F1F1', paddingBottom: '50px' }}>
        <div className="row container center">
          <h1 style={{ margin: '0' }}>Frequently Asked Questions</h1>
         
          {/* <div className="clearfix"></div>
					<Link to="/login" className="btn-large">
						CREATE A PET TALE
					</Link> */}
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <br />

      <div className="row container">
        <div className="col s12 m12 l8 offset-l2">
          <ul className="collapsible noBgCollapsable">
            <li>
              <div className="collapsible-header noBgCollapsableHead">
                My pet has died. What do I do next?
                <i className="material-icons right">add</i>
              </div>
              <div className="collapsible-body noBgCollapsableBody">
                <p>
                  If you require immediate service please view our location
                  serving your area.
                </p>
                <p>
                  7200 Hodgson Memorial Drive
                  <br />
                  Savannah, GA 31406
                  <br />
                  (912) 800-7005
                </p>

                
              </div>
            </li>
            <li>
              <div className="collapsible-header noBgCollapsableHead">
                Cremation Options?
                <i className="material-icons right">add</i>
              </div>
              <div className="collapsible-body noBgCollapsableBody">
                <p>
                  <b>
                    If you’re considering cremation as final care for your pet,
                    this informative page is the right place to start. It
                    includes an overview of the process and explains your
                    cremation options.
                  </b>
                </p>

                <p>
                  The process used to cremate pets is the same exact process
                  used for human cremation. Cremation is a form of disposition
                  that uses heat to transform your pet’s physical remains to its
                  basic elements. Sometimes these are referred to as “ashes”, or
                  “cremains” –a word blended from “cremated remains”.
                </p>

                <p>
                  Most pet cremation providers offer two types of cremation; Fox
                  & Weeks is the only organization to offer three types of{' '}
                  <b>pet cremation services</b>.
                </p>

                <p>
                  Unlike the human cremation profession, there is very little or
                  no regulation when it comes to pet cremations. Thus, different
                  providers might be using the same terminology but for
                  different types of cremation services. It is very important to
                  understand what type of cremation you are receiving, so please
                  read the following carefully!
                </p>
                <ul className="discStyle">
                  <li>
                    A <b>“Private“</b> cremation is a cremation procedure during
                    which only one animal’s body is present in the cremation
                    chamber during the cremation process and the cremated
                    remains of the pet are to be returned to its owner.
                  </li>
                  <li>
                    A <b>“Semi-Private”</b> (commonly referred to as “Individual
                    or Partioned or Segregated Cremation”) is a cremation
                    procedure during which more than one animal’s body is
                    present in the cremation chamber and cremated at the same
                    time. This is the cremation process that has been used for
                    decades by the vast majority of pet cremation providers. The
                    vast majority of pet cremation providers do not use any
                    means of physical barrier between pets. The lack of physical
                    barriers can and will lead to significant co-mingling of
                    pet’s cremated remains. Unfortunately, many people and many
                    veterinarians over the years and still to this day have been
                    mis-lead by the term “Individual Cremation” as it denotes
                    only one pet being cremated at a time. This is not the case
                    with this type of cremation and use of terminology!
                    <br />
                    <br />
                    Be rest assured that Pet Passages, the leader in the pet
                    loss profession, has designed, developed and utilizes it own
                    patent pending stainless steel dividers between pets and has
                    coined the term “Semi-Private Cremation” to help avoid
                    confusion. Please note: Due to a number of factors and by
                    virtue of multiple pets being cremated within the same
                    chamber, active co-mingling of cremated remains can occur
                    but is significantly reduced if not eliminated.
                  </li>
                  <li>
                    A <b>“Communal”</b> (or Batch”) is a cremation procedure
                    where multiple pets are cremated together without any form
                    of physical barrier. These co-mingled cremated remains are
                    not returned to the owners.
                  </li>
                </ul>
                <p>
                  Because of the lack of regulation, it is important for you to
                  ask your pet cremation provider what they mean when they use
                  the term “individual cremation”.
                </p>
                <p>
                  We realize that each family has their own unique set of wishes
                  when it comes to memorializing their pet. Our staff will work
                  with your family to tailor a distinctive pet cremation a
                  private family goodbye, or a memorial service to meet all of
                  your needs.
                </p>
                <p>
                  Since our pet funeral directors control the entire process, we
                  can ensure that your pet’s cremated remains will be available
                  for return much faster than other providers, typically within
                  days of your pet’s passage. We guarantee that your pet will be
                  treated with dignity and respect and we will care for your
                  companion pet with the same reverence as we would treat any
                  other member of your family. 
                </p>
                
              </div>
            </li>
            
           
            
            
            <li>
              <div className="collapsible-header noBgCollapsableHead">
                What should I do when my pet is nearing the end?
                <i className="material-icons right">add</i>
              </div>
              <div className="collapsible-body noBgCollapsableBody">
                <p>
                  <b>Naturally, you want to ease their way.</b>
                </p>

                <p>
                  The decision to euthanize is a very difficult decision to make
                  but is a necessary decision when the animal is starting to
                  suffer as a result of its incurable disease and drugs are no
                  longer available or enough to help relieve this suffering.{' '}
                  <b>
                    Please consult with your veterinary professional! If your
                    pet is not in pain, there are many things you can do at home
                    to naturally help your pet through the process of letting go
                    of life. Here are some helpful recommendations: Please
                    consult with your veterinary professional!
                  </b>
                </p>
                <ol className="numStyle">
                  <li>
                    As you wish to comfort your pet, speak soothingly to him or
                    her. Keep lighting soft, and you may also want to put on
                    some soothing music in the background.
                  </li>

                  <li>
                    Since his or her circulation could be slowing down, you may
                    wish to cover your pet with a blanket.
                  </li>

                  <li>
                    Do not force him or her to eat. Keep their mouth moistened
                    if possible, by gently dripping water onto their tongue.
                  </li>

                  <li>
                    Recognize that when death is near, their senses will begin
                    to fail. The first is their sense of smell, followed by the
                    senses of taste and sight. The last to go will be their
                    hearing.
                  </li>

                  <li>
                    Gently reposition him or her, if needed, to make them more
                    comfortable.
                  </li>

                  <li>
                    Allow your pet to choose the place he or she wishes to lay
                    down, and then make him or her as comfortable as possible by
                    surrounding them with well-loved toys, pictures, etc.
                  </li>

                  <li>
                    Respect their need for solitude, but don’t feel a need to
                    isolate your pet from the family. Just like you, your pet
                    would like to be surrounded by those people he or she loves
                    most. Stay close, but don’t crowd your pet, or make them
                    nervous by being too close.
                  </li>
                </ol>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="clearfix"></div>
      <br />
      <div className="row container cursors"></div>

      <div className="clearfix"></div>
      <br />
      <br />
    </div>
  );
};

export default HomePage;
