import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import priceList from './FoxWeeks-Pets-SalesSheet-2022.3281d372.pdf';
import pet001 from './pet001.jpg';
import pet002 from './pet002.jpg';
import pet003 from './pet003.jpg';
import pawred from './pawred.png';
import petcat from './Pet-Catalog.pdf';
import { appendScript, removeScript } from '../../utils/scripts';
import { Helmet } from 'react-helmet';

const HomePage = () => {
	useEffect(() => {
		appendScript('/assets/js/css3-animate-it.js');

		return () => removeScript('/assets/js/css3-animate-it.js');
	}, []);

	return (
		<div className="">
			<Helmet>
				<title>Our Services : Pets at Peace Savannah</title>
			</Helmet>
			<div style={{ backgroundColor: '#F1F1F1', paddingBottom: '50px' }}>
				<div className="row container center">
					<h1 style={{ margin: '0' }}>Our Services</h1>
					<p className="col s12 m12 l8 offset-l2">
					We realize that each family has their own unique wishes when it comes to memorializing their pet.
					Our staff will work with your family to tailor a distinctive pet cremation or a private family goodbye. 
					As part of our extensive pet services, we can offer the following:
						<br />
						<br />
						<img src={pawred} /> 24/7 transportation of your pet from your home or veterinary hospital to Fox & Weeks &nbsp; 
						<img src={pawred} /> Private family goodbyes  &nbsp; 
						<img src={pawred} /> Large selection of pet memorial products  &nbsp; 
						<img src={pawred} /> A personalized card with your pet's paw print  &nbsp; 
						<img src={pawred} /> <a href={petcat} target="_blank">Engraved cremation jewelry</a> 	 &nbsp; 
					</p>
					{/* <div className="clearfix"></div>
					<Link to="/login" className="btn-large">
						CREATE A PET TALE
					</Link> */}
				</div>
			</div>
			<div className="clearfix"></div>
			<br />
			<br />

			<div className="row container">
				<div className="grid">
				
					<div className="col s6 m4 l4 offset-l2 animatedParent animateOnce">
						<figure className="effect-lily card animated fadeInUpShort">
							<img src={pet001} alt="img12" />
							<figcaption>
								<div>
									<h2>Immediate Need</h2>
									<p>Read More</p>
								</div>
								<a href="#Immediate-Need">View more</a>
							</figcaption>
						</figure>
					</div>
					<div className="col s6 m4 l4 animatedParent animateOnce">
						<figure className="effect-lily card animated fadeInUpShort">
							<img src={pet002} alt="img12" />
							<figcaption>
								<div>
									<h2>Cremation Services</h2>
									<p>Read More</p>
								</div>
								<a href="#Cremation-Services">View more</a>
							</figcaption>
						</figure>
					</div>
					
					{/* <div className="col s6 m4 l3 animatedParent animateOnce">
						<figure className="effect-lily card animated fadeInUpShort">
							<img src={pet004} alt="img12" />
							<figcaption>
								<div>
									<h2>Pet Funeral</h2>
									<p>Read More</p>
								</div>
								<a href="#Pet-Funeral">View more</a>
							</figcaption>
						</figure>
					</div> */}
				</div>
			</div>

			<div className="row container">
				<div className="col s12 m12 l8 offset-l2">
					<div id="Immediate-Need">
						<h2>Immediate Need</h2>
						<p>What to do if you are in immediate need of our services:</p>

						<p>
							Our service area extends throughout the Coastal Empire and the
							South Carolina Lowcountry. We offer appointments at our Pets at
							Peace facility between the hours of 9 am-5 pm Monday through
							Friday. One of our Pet Care Specialists will transfer your pet
							from your home or veterinary hospital to Fox & Weeks. Our goal is
							to make this transition as easy as possible for you and your
							family. Please call us with any questions (912) 352-7200.
						</p>
					</div>
					<div id="Cremation-Services">
						<h2>Cremation Services</h2>
						<p>
							If you're considering cremation as final care for your pet, this
							page includes an overview of the process and explains your
							cremation options.
						</p>

						<p>
							Since our funeral professionals control the entire process, we can
							ensure that your pet's cremated remains will be available for
							return much faster than other providers, typically within days of
							your pet's passage. Because Fox & Weeks is your local funeral
							home, we can guarantee that your pet will be treated with dignity
							and respect, and we will care for your companion pet with the same
							reverence as we would treat any other member of your family. We
							have also developed Frequently Asked Questions to provide you with
							more information on pet cremation.
						</p>

						<p>
							<i>
								To assist you, we have prepared an informative Price List of
								services we provide.
							</i>
						</p>
						<p>
							<a
								href={priceList}
								target="_blank"
								className="btn-large"
								rel="noopener noreferrer"
							>
								Download Price List
							</a>
						</p>
						{/* <p className="red-text">(Kevin, I will get you the price list and we can link to it as a PDF)</p> */}
					</div>

					
				</div>
			</div>

			<div className="clearfix"></div>
			<br />
			<div className="row container cursors"></div>

			<div className="clearfix"></div>
			<br />
			<br />
		</div>
	);
};

export default HomePage;
