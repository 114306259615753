import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { appendScript, removeScript } from '../../utils/scripts';

const HomePage = () => {
  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>Why Choose Us : Pets at Peace Savannah</title>
      </Helmet>
      <div style={{ backgroundColor: '#F1F1F1', paddingBottom: '50px' }}>
        <div className="row container center">
          <h1 style={{ margin: '0' }}>It’s never easy to say goodbye</h1>
          <div className="col s12 m12 l8 offset-l2">
            <p>
              Many families have placed their trust in Fox & Weeks over the
              years, and we have always been dedicated to serving the entire
              community and the entire family. Pets are very important members
              of our families that profoundly touch our lives. As trained
              funeral professionals, <b>Pets at Peace by Fox & Weeks</b>{' '}
              understands the genuine sorrow that can be caused by the death of
              a loved one, especially an animal companion. That's why we offer
              families individual pet cremations and customized memorial
              services to help you say goodbye to your pet in a loving and
              dignified way.
            </p>
          </div>

          {/* <div className="clearfix"></div>
					<Link to="/login" className="btn-large">
						CREATE A PET TALE
					</Link> */}
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <br />
      <div className="row container">
        <div className="col s12 m12 l8 offset-l2">
          <p>
            Our experienced, compassionate funeral professionals understand how
            important it is to properly say goodbye to every member of your
            family. Additionally, our staff is available to help your family
            create the perfect tribute to reflect your pet's unique life. These
            comforting events offer you and your family the opportunity to share
            stories about your beloved pet and say your final goodbyes. Finally,
            we offer you the opportunity to memorialize your cherished friend by
            expressing your love and telling their story through words, pictures
            and music on our <Link to="/Pet-Tales">Pet Tales</Link> page.
          </p>
        </div>
      </div>

      <div className="clearfix"></div>
      <div className="row container cursors"></div>

      <div className="clearfix"></div>
      <br />
      <br />
    </div>
  );
};

export default HomePage;
