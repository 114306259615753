import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ArrowToTop from "../ScrollTopArrow";
import "./hero.css";
import NavLinks from "./navlinks";
import M from "materialize-css";
import logo from "./FoxWeeksPETS-logo.png";

const topStyle = {
	position: "absolute",
	border: "0",
	boxShadow: "0 0 0",
};

const Home = (props) => {
	useEffect(() => {
		M.Sidenav.init(document.getElementById("mobile-nav"));
	}, []);

	const closeMobileNav = () => {
		let elem = document.getElementById("mobile-nav");
		let instance = M.Sidenav.getInstance(elem);
		instance.close();
	};

	return (
		<div className="headBg">
			<div className="container" style={{ minHeight: "105px" }}>
				<div className="nav-wrapper">
					<header className="row" style={{ position: "relative", zIndex: 100 }}>
						<nav className="transparent" style={topStyle}>
							<a
								href="#!"
								data-target="mobile-nav"
								className="sidenav-trigger center"
							>
								<i className="material-icons">menu</i>

								<span
									style={{
										fontSize: "14px",
										display: "block",
										lineHeight: "1.5",
										fontWeight: "700",
									}}
								>
									MENU
								</span>
							</a>

							<Link className="" to="/">
								<img
									src={logo}
									alt=""
									className="logoAdj"
									style={{ marginTop: "20px" }}
								/>
							</Link>
						</nav>
					</header>
					{/* <header className="row" style={{ position: "relative" }}>
						<nav className="transparent" style={topStyle}>
							<ul
								className="right hide-on-med-and-down"
								style={{ margin: "25px 0px 0 0" }}
							>
								<NavLinks closeMobileNav={closeMobileNav} />
							</ul>
						</nav>
					</header> */}
				</div>
			</div>
			<ul className="sidenav uppercaseTxt" id="mobile-nav">
				<li style={{ height: "190px" }}>
					<Link
						className=""
						to="/"
						style={{ border: "0" }}
						onClick={() => closeMobileNav()}
					>
						<img
							src={logo}
							alt=""
							className="responsive-img"
							style={{ marginTop: "15px" }}
						/>
					</Link>
				</li>
				<NavLinks closeMobileNav={closeMobileNav} />
				{/* <li>
					<Link
						className="hvr-underline-reveal"
						to="/Locations"
						onClick={() => closeMobileNav()}
					>
						Why Choose Us
					</Link>
				</li> */}
				<p className="ssIcon center">
					<a
						href="https://g.page/FoxandWeeks?share"
						target="_blank"
						rel="noopener noreferrer"
						className="fbIcon"
					>
						<i className="fas fa-map-pin"></i>
					</a>
					<a
						href="https://www.facebook.com/foxandweekspetsatpeace"
						target="_blank"
						rel="noopener noreferrer"
						className="fbIcon"
					>
						<i class="fab fa-facebook-f"></i>
					</a>
					<br />
					7200 Hodgson Memorial Drive <br />
					Savannah, GA 31406
					<br />
					(912) 800-7005
				</p>
				
			</ul>
			{/* <div
				style={{
					height: "80px",
					backgroundColor: "#F1F1F1",
					position: "absolute",
					top: "439px",
					width: "100%",
					zIndex: "-1",
				}}
			>
				asadasdsd
			</div> */}

			<ArrowToTop />
		</div>
	);
};

export default React.memo(Home);
