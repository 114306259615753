import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { appendScript, removeScript } from '../../utils/scripts';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import M from 'materialize-css';
import { Helmet } from 'react-helmet';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const HomePage = () => {
  const form = {
    name: '',
    email: '',
    phone: '',
    petname: '',
    message: '',
  };
  const { register, handleSubmit, errors } = useForm({ defaultValues: form });
  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  const sendContactForm = (values) => {
    const _values = { ...form, ...values };

    Axios({
      method: 'POST',
      url: `${URL}webmail.cfc?method=email`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: _values,
    })
      .then((res) => res.status === 200 && res.data)
      .then(
        (data) =>
          data.success &&
          M.toast({ html: 'Thanks, we will get back to you soon!' })
      )
      .catch((e) => console.log(e));
  };

  return (
    <div className="">
      <Helmet>
        <title>Contact Us : Pets at Peace Savannah</title>
      </Helmet>
      <div style={{ backgroundColor: '#F1F1F1', paddingBottom: '50px' }}>
        <div className="row container">
          <h1 className="center" style={{ margin: '0' }}>
            Contact Us
          </h1>
          <br />
          <div className="col s12 m6 l4 offset-l2">
            <h3>Fox & Weeks Funeral Directors (Hodgson Memorial)</h3>
            <p className="ssIcon valign-wrapper" style={{ marginBottom: '0' }}>
              <div>
                <a
                  href="https://g.page/FoxandWeeks?share"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fbIcon"
                >
                  <i className="fas fa-map-pin"></i>
                </a>
              </div>
              <div>
                7200 Hodgson Memorial Drive <br />
                Savannah, GA 31406
              </div>
            </p>
            <p className="ssIcon" style={{ margin: '0' }}>
              <a href="tel:9123527200" className="igIcon">
                <i className="fas fa-mobile-alt"></i>
              </a>{' '}
              (912) 800-7005
            </p>
            <p className="ssIcon" style={{ margin: '0' }}>
              <a href="mailto:petsatpeace@foxandweeks.com" className="eIcon">
                <i className="far fa-paper-plane"></i>
              </a>{' '}
              petsatpeace@foxandweeks.com
            </p>
          </div>
          <div className="hide-on-med-and-up">
            <div className="clearfix"></div>
            <br />
            <br />
          </div>
          
          {/* <div className="clearfix"></div>
					<Link to="/login" className="btn-large">
						CREATE A PET TALE
					</Link> */}
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <br />

      <div className="row container">
        <form
          onSubmit={handleSubmit(sendContactForm)}
          className="col s12 m12 l8 offset-l2"
        >
          <h2>Send Us a Message</h2>
          <div
            style={{
              border: '1px solid rgb(221, 221, 221)',
              borderRadius: '10px',
              padding: '15px 10px 20px',
            }}
          >
            <div className="input-field col s12 m6 l6">
              <input
                ref={register({ required: 'Name is required' })}
                id="name"
                name="name"
                type="text"
                className="validate"
              />
              <label for="name">*Your name</label>
              <span>{errors && errors.name && errors.name.message}</span>
            </div>
            <div className="input-field col s12 m6 l6">
              <input
                ref={register({ required: 'Email is required' })}
                name="email"
                id="email"
                type="text"
                className="validate"
              />
              <label for="email">*Your email address</label>
              <span>{errors && errors.email && errors.email.message}</span>
            </div>
            <div className="input-field col s12 m6 l6">
              <input
                ref={register}
                id="phone"
                name="phone"
                type="text"
                className="validate"
              />
              <label for="phone">Your phone number</label>
            </div>
            <div className="input-field col s12 m6 l6">
              <input
                ref={register}
                name="petname"
                id="petname"
                type="text"
                className="validate"
              />
              <label for="petname">Your pet name</label>
            </div>
            <div className="input-field col s12">
              <textarea
                name="message"
                ref={register}
                id="message"
                type="text"
                className="materialize-textarea validate"
              ></textarea>
              <label for="message">Your message ...</label>
            </div>
            <div className="col s12 m12 l12 center">
              <button type="submit" name="submit" className="btn">
                Send Message
              </button>
            </div>
            <div className="clearfix"></div>
          </div>
        </form>
      </div>

      <div className="clearfix"></div>
      <br />
      <div className="row container cursors"></div>

      <div className="clearfix"></div>
      <br />
      <br />
    </div>
  );
};

export default HomePage;
