import React from 'react';
// import { Link } from 'react-router-dom';
import moment from 'moment';
import Gallery from 'react-grid-gallery';
import Loading from '../../components/Loader';
import candle from './candle-for-pet.png';
import config from '../../config';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import M from 'materialize-css';
import { Helmet } from 'react-helmet';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const PostCommentForm = ({ tale, onPost }) => {
	const { register, handleSubmit, errors } = useForm({
		name: '',
		email: '',
		comment: '',
		candle: '',
	});

	const onSubmit = (values) => {
		// post comment
		values.tale = tale.id;

		Axios({
			method: 'POST',
			url: `${URL}/comments.cfc?method=create`,
			data: values,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((res) => res.status === 200 && res.data)
			.then(
				(data) => data.success && M.toast({ html: 'Thanks for your comment.' })
			)
			// .then((data) => {
			// 	if (data.success) {
			// 		values.target.reset();
			// 		M.toast({ html: 'Thanks for your comment.' });
			// 	}
			// })
			.finally((x) => onPost());
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="input-field col s12">
				<textarea
					id="comment"
					name="comment"
					type="text"
					className="materialize-textarea validate"
					ref={register({
						required: 'Your message is required',
					})}
				></textarea>
				<label for="comment">*Share your message ...</label>
				{errors && errors.comment && (
					<span className="red-text helper-text">{errors.comment.message}</span>
				)}
			</div>
			<div className="input-field col s12 m6 l6">
				<input
					id="name"
					name="name"
					type="text"
					className="validate"
					ref={register({
						required: 'Your name is required',
					})}
				/>
				<label for="name">*Your name</label>
				{errors && errors.name && (
					<span className="red-text helper-text">{errors.name.message}</span>
				)}
			</div>
			<div className="input-field col s12 m6 l6">
				<input
					id="email"
					name="email"
					type="text"
					className="validate"
					ref={register({
						required: 'Your email address is required',
					})}
				/>
				<label for="email">*Your email address</label>
				{errors && errors.email && (
					<span className="red-text helper-text">{errors.email.message}</span>
				)}
			</div>
			<div className="col s12 m6 l6" style={{ padding: '10px 0.75rem 20px' }}>
				<label>
					<input type="checkbox" name="candle" ref={register()} />
					<span>Light a Candle</span>
				</label>
			</div>
			<div className="col s12 m6 l6">
				<button type="submit" name="search" className="btn">
					Post Message
				</button>
			</div>
		</form>
	);
};

const HomePage = ({ tale }) => {
	const [images, setImages] = React.useState([]);
	const [render, setRender] = React.useState(0);

	React.useEffect(() => {
		setImages(
			tale.images.reduce((arr, el) => {
				const imgs = {
					...el,
					src: `${el.filename}`,
					thumbnail: `${el.filename}`,
					thumbnailWidth: 320,
					thumbnailHeight: 'auto',
				};
				return arr.concat([imgs]);
			}, [])
		);
	}, [tale.images]);

	return (
		<div className="">
			<Helmet>
				<title>Pet Tales : {tale.name} : Pets at Peace Savannah</title>
			</Helmet>
			<div style={{ backgroundColor: '#F1F1F1', padding: '25px 0' }}>
				<div className="row container center valignAdj">
					<div className="col s10 offset-s1 m4 offset-m1 l3 offset-l2">
						<div id="square" style={{ marginBottom: '10px' }}>
							<div
								className="sq-content valign-wrapper petCirle heart"
								style={{ backgroundImage: `url(${tale.images[0].filename})` }}
							></div>
						</div>

						{/* <div>
							<img
								src={tale.images[0].filename}
								alt=""
								className="responsive-img card petCirle heart petCirle"
							/>
						</div> */}
					</div>
					<div className="col s12 m6 l5" style={{ marginLeft: '0' }}>
						<h1 style={{ margin: '0' }}>{tale.name}</h1>
						<p>
							{moment(tale.dob).format('MMMM DD, YYYY')} -{' '}
							{moment(tale.dop).format('MMMM DD, YYYY')}
						</p>
						<p className="ssIcon">
							{tale.facebook.trim().length > 0 && (
								<a href={tale.facebook} target="_blank" className="fbIcon">
									<i className="fab fa-facebook-f"></i>
								</a>
							)}
							{tale.twitter.trim().length > 0 && (
								<a className="twIcon">
									<i className="fab fa-twitter"></i>
								</a>
							)}
							{tale.instagram.trim().length > 0 && (
								<a className="igIcon">
									<i className="fab fa-instagram"></i>
								</a>
							)}
							{tale.email.trim().length > 0 && (
								<a className="eIcon">
									<i className="far fa-envelope-open"></i>
								</a>
							)}
						</p>
					</div>
				</div>
			</div>
			<div className="clearfix"></div>
			<br />
			<br />

			<div className="row container">
				<div className="col s12 m6 l5">
					<Gallery images={images} />
				</div>
				<div className="col s12 m6 l7">
					<div dangerouslySetInnerHTML={{ __html: tale.body }} />
					{/* <h2>The Life and Times of Bonaventure</h2>
               <p>
                  Bonaventure Rosenzweig, fourteen and half years old, died at this home early
                  Monday morning, June 16, 2014 after a four day illness surrounded by his mishpacha
                  (family).
               </p>
               <p>
                  I was abandoned when I was eight weeks old for maybe 30 seconds when a guy saw me
                  walking on the banks of the Wilmington River in a place called Bonaventure
                  Cemetery in March of 2000. He stopped working to come check me out. From that
                  moment on, I knew this was b’sherit (meant to be). What a blast I had in those 14
                  years and 4 months. I really enjoyed helping out as best as I knew how until May
                  of 2007 when Marvin Rosenzweig, Of Blessed Memory, was on this earth with us. He
                  sure knew what a dog likes for snacks. I got to travel to two out of town weddings
                  in Atlanta and Baltimore and a high school graduation in South Bend, Indiana. What
                  a great trip that was!!! The last few years I occupied my day times by supervising
                  that guy that picked me up years ago making sure he was doing things right out in
                  my namesake. I want to apologize for things I should not have done as well as
                  anyone that received a bit of a fright with my greetings from the truck early in
                  the mornings on Atlas Street or any other place for that matter.
               </p>
               <p>
                  If you have any stories or pictures to shares, please do. I hear I was well known
                  in the community. I need some good reading.
               </p>
               <p>
                  I want to thank that guy that picked me up and his family: Harvey, Lynn, Gail,
                  Susan Rosenzweig and Yaacov, Yosef and Shoshana Sher and the rest of the
                  mishpacha.
               </p> */}
					<h2>Leave a Message</h2>
					<p>
						Your email address will not be published. Required fields are marked
						*
					</p>
					<div
						style={{
							border: '1px solid rgb(221, 221, 221)',
							borderRadius: '10px',
							padding: '15px 10px 20px',
						}}
					>
						<PostCommentForm tale={tale} onPost={() => setRender(render + 1)} />
						<div className="clearfix"></div>
					</div>
					<div style={{ position: 'relative' }}>
						<Comments tale={tale} render={render} />
					</div>
				</div>
			</div>

			<div className="clearfix"></div>
			<br />
			<div className="row container"></div>

			<div className="clearfix"></div>
			<br />
			<br />
		</div>
	);
};

const Comments = ({ tale, render }) => {
	const [comments, setComments] = React.useState([]);
	const [fetching, setFetching] = React.useState(false);

	const getComments = (i) => {
		setFetching(true);

		Axios({
			method: 'GET',
			url: `${URL}/comments.cfc?method=getByTale&tale=${i}`,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((res) => res.status === 200 && res.data)
			.then((data) => setComments(data))
			.finally((e) => setFetching(false));
	};

	React.useEffect(() => {
		getComments(tale.id);
	}, [tale.id, render]);

	return !fetching ? (
		<>
			<h2>Message Wall ({comments.length})</h2>
			{comments.map((comment, x) => (
				<Comment key={x} comment={comment} />
			))}
		</>
	) : (
		<Loading />
	);
};

const Comment = ({ comment }) => {
	return (
		<div
			style={{
				border: '1px solid rgb(221, 221, 221)',
				borderRadius: '10px',
				padding: '10px 15px 10px',
				margin: '20px 0',
			}}
		>
			<h3>{comment.name}</h3>
			<p>{comment.comment}</p>
			{comment.candle > 0 && (
				<p style={{ textAlign: 'right', margin: '0' }}>
					<img src={candle} alt="" style={{ width: '190px' }} />
				</p>
			)}
		</div>
	);
};

export default HomePage;
