import React from 'react';
//import withAuthentication from "./context/auth-provider";
import Route, { HomeRoute } from './routes';
import HomePage from './containers/HomePage';
import PetTalesPage from './containers/PetTalesPage';
import PetTalesDetailPage from './containers/PetTalesPage/detail';
// import PetTalesDetailPage from './containers/PetTalesDetailPage';
import ContactPage from './containers/ContactPage';
import WhyChooseUsPage from './containers/WhyChooseUsPage';
import OurServicesPage from './containers/OurServicesPage';
import AboutUsPage from './containers/AboutUsPage';
import FAQsPage from './containers/FAQsPage';
import NewTalePage from './containers/NewTalePage';
//import LoginPage from "./containers/LoginPage";

import NotFoundPage from './containers/NotFoundPage';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

//import CartPage from "./containers/CartPage";
//import CartCheckoutPage from "./containers/CartPage/cart.checkout";
//import CartReviewPage from "./containers/CartPage/cart.review";
//import CartConfirmationPage from "./containers/CartPage/cart.confirmation";
// import categoryService from './services/categories';
// import Loader from './components/Loader';

//const LoginWithAuthData = withAuthentication(LoginPage);

const App = () => {
   return (
      <Router>
         <React.Fragment>
            <Switch>
               <HomeRoute exact path="/" component={HomePage} />
               <HomeRoute exact path="/Why-Choose-Us" component={WhyChooseUsPage} />
               <HomeRoute exact path="/Our-Services" component={OurServicesPage} />
               <HomeRoute exact path="/About-Us" component={AboutUsPage} />
               <HomeRoute exact path="/FAQs" component={FAQsPage} />
               <HomeRoute exact path="/Contact-Us" component={ContactPage} />
               <HomeRoute exact path="/New-Tale" component={NewTalePage} />
               <HomeRoute exact path="/Pet-Tales" component={PetTalesPage} />
               <HomeRoute exact path="/Pet-Tales/:slug" component={PetTalesDetailPage} />
               <HomeRoute exact path="/Pet-Tales/:slug/:date" component={PetTalesDetailPage} />
               <Route component={NotFoundPage} />
            </Switch>
         </React.Fragment>
      </Router>
   );
};

export default App;
