import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import familypet from "./family-and-pet.jpg";
import oldpet from "./old-pet.jpg";
import kidpet from "./kid-and-pet.jpg";
import { appendScript, removeScript } from "../../utils/scripts";

const HomePage = () => {
	useEffect(() => {
		appendScript("/assets/js/css3-animate-it.js");

		return () => removeScript("/assets/js/css3-animate-it.js");
	}, []);

	return (
		<div className="">
			{/* <div className="heroImgAdj" style={heroImage}>
				<div className="container row">
					<div className="s12 center">
						<div className="hero-text white-text">
							<h1 className="heroBigTxtAdj">Doctor up your dishes!</h1>
							<p className="heroSmallTxtAdj">
								with Dr. Pete’s delicious
								<br />
								mixes, marinades and dressings.
							</p>
							<br />
							<Link to="/login" className="btn-large">
								SHOP NOW
							</Link>
						</div>
					</div>
				</div>
			</div> */}
			<div style={{ backgroundColor: "#F1F1F1", paddingBottom: "50px" }}>
				<div className="row container center">
					<h1 style={{ margin: "0" }}>Because our pets are family.</h1>
					<p className="col s12 m12 l6 offset-l3">
						Pets at Peace by Fox & Weeks is proud to be able to offer pet loss
						services to Coastal Georgia and the South Carolina Lowcountry
						adhering to the same high standards Fox & Weeks Funeral Directors
						has established in 130 years of serving our neighbors in Savannah.
					</p>
					<div className="clearfix"></div>
					<Link to="/Why-Choose-Us" className="btn-large">
						WHY CHOOSE US
					</Link>
				</div>
			</div>
			<div className="clearfix"></div>

			<section className="SB-blue-text">
				<br />
				<br />
				<br />
				<div className="row container wrap-contact displayAdj">
					<div className="col s12 m6 l6 padAdj center-align animatedParent animateOnce">
						<img
							src={familypet}
							alt=""
							className="responsive-img card animated fadeInUpShort"
						/>
					</div>
					<div className="col s12 m6 l6 padAdj">
						<h1>Love and Joy</h1>

						<p>
							Pets are very important members of our families that profoundly
							touch our lives. Because pets are unconditionally loyal and bring
							us immeasurable happiness, love and joy, it can be devastating to
							lose such an adored friend.
						</p>

						<p>
							As trained funeral professionals, Pets at Peace by Fox & Weeks
							understands the genuine sorrow that can be caused by the death of
							a loved one, especially an animal companion.
						</p>
					</div>
				</div>
				<br />
				<br />

				<div className="row container wrap-contact displayAdj">
					<div className="col s12 m5 l4 push-m7 push-l8 padAdj center-align animatedParent animateOnce">
						<img
							src={oldpet}
							alt=""
							className="responsive-img card animated fadeInUpShort"
						/>
					</div>
					<div className="col s12 m7 l8 pull-m5 pull-l4 padAdj">
						<h1>Share Your Story</h1>
						<p>
							Pet Tales is an online pet obituary and tribute gateway that is
							designed pet parents to honor a pet's life and share their story
							with others. By sharing a Pet Tale, you will not only embrace the
							memories of your pet but also create a permanent place to remember
							your faithful companion that brought so much happiness. Please
							feel free to page through our memory album below to read some of
							the Pet Tales shared by others.
						</p>
						<div className="clearfix"></div>
						<Link to="/Pet-Tales" className="btn-large">
							SHARE YOUR STORY
						</Link>
					</div>
				</div>
				<br />
				<br />

				<div className="row container wrap-contact displayAdj">
					<div className="col s12 m6 l6 padAdj center-align animatedParent animateOnce">
						<img
							src={kidpet}
							alt=""
							className="responsive-img card animated fadeInUpShort"
						/>
					</div>
					<div className="col s12 m6 l6 padAdj">
						<h1>Caring for Your Family</h1>
						<p>
							Our experienced, compassionate funeral professionals understand
							how important it is to properly say goodbye to every member of
							your family. Our staff is available to help your family create the
							perfect tribute to reflect your pet's unique life. Our goal is to
							give you and your family the opportunity to share stories about
							your beloved pet and say your final goodbyes.
						</p>
						<div className="clearfix"></div>
						<Link to="/Our-Services" className="btn-large">
							OUR SERVICES
						</Link>
					</div>
				</div>
				<br />
				<br />
				<br />
			</section>

			<div className="clearfix"></div>
		</div>
	);
};

export default HomePage;
