import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PetDetails from '../PetTalesDetailPage';
import Loading from '../../components/Loader';
import { appendScript, removeScript } from '../../utils/scripts';
import Axios from 'axios';
import config from '../../config';
import moment from 'moment';
import { Helmet } from 'react-helmet';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const HomePage = ({ setQ, data }) => {
	const [search, setSearch] = useState('');

	useEffect(() => {
		appendScript('/assets/js/css3-animate-it.js');

		return () => removeScript('/assets/js/css3-animate-it.js');
	}, []);

	return (
		<React.Fragment>
			<Helmet>
				<title>Pet Tales : Pets at Peace Savannah</title>
			</Helmet>
			<div className="row container">
				<div className="input-field col s10 m7 offset-m2 l7 offset-l2">
					<input
						id="first_name2"
						type="text"
						className="validate"
						onBlur={() => setQ(search)}
						onChange={(e) => setSearch(e.target.value)}
						value={search}
					/>
					<label htmlFor="first_name2">Search Pets</label>
				</div>
				<div className="col s2 m1 l1">
					<button
						type="submit"
						name="search"
						className="btn-large"
						style={{
							borderRadius: '50%',
							padding: '0',
							width: '60px',
							height: '60px',
						}}
					>
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>

			<div className="clearfix"></div>
			<br />
			<div className="row container cursors">
				{data.map((tale, i) => (
					<div
						key={i}
						className="col s6 m4 l3 center-align petCircleItem animatedParent animateOnce"
					>
						<Link
							to={`/Pet-Tales/${tale.slug}/${moment(tale.dop)
								.add(1, 'days')
								.format('YYYYMMDD')}`}
							className="animated fadeInUpShort"
						>
							<div id="square" style={{ marginBottom: '10px' }}>
								<div
									className="sq-content valign-wrapper petCirle heart card"
									style={{ backgroundImage: `url(${tale.images[0].filename})` }}
								>
									{/* <img src={tale.images[0].filename} alt="" style={{ minWidth: '100%', minHeight: '100%', margin: '0 auto' }} className="responsive-img card  heart petCirsle animated fadeInUpShort" /> */}
								</div>
							</div>
						</Link>
						<div className="clearfix"></div>
						<Link
							to={`/Pet-Tales/${tale.slug}/${moment(tale.dop)
								.add(1, 'days')
								.format('YYYYMMDD')}`}
							className=""
						>
							{tale.name}
						</Link>
					</div>
				))}
			</div>

			<div className="clearfix"></div>
			<br />
			<br />
		</React.Fragment>
	);
};

const Page = () => {
	const [q, setQ] = React.useState('');
	const [qr, setQr] = React.useState({});
	const [data, setData] = React.useState([]);
	const [fetching, setFetching] = React.useState(false);

	const searchProducts = (qs) => {
		if (!(qs in qr) || Date.now() - qr[qs].exp > 180000) {
			// exists, check expiration
			setFetching(true);
			Axios.get(`${URL}tales.cfc?method=search&q=${qs}`)
				.then((response) => response.status === 200 && response.data)
				.then((data) => {
					setData(data);
					setQr({ ...qr, [qs]: { exp: Date.now(), data } });
				})
				.catch((e) => console.log(e))
				.finally((e) => setFetching(false));
		} else {
			data !== qr[qs].data && setData(qr[qs].data);
		}
	};

	useEffect(() => {
		searchProducts('');
	}, []);

	useEffect(() => {
		searchProducts(q);
	}, [q]);

	return (
		<div style={{ position: 'relative' }}>
			<div style={{ backgroundColor: '#F1F1F1', paddingBottom: '50px' }}>
				<div className="row container center">
					<h1 style={{ margin: '0' }}>Pet Tales</h1>
					<p className="col s12 m12 l8 offset-l2">
						Pet Tales is an online pet obituary and tribute gateway that is
						designed pet parents to honor a pet's life and share their story
						with others. By sharing a Pet Tale, you will not only embrace the
						memories of your pet but also create a permanent place to remember
						your faithful companion that brought so much happiness. Please feel
						free to page through our memory album below to read some of the Pet
						Tales shared by others.
					</p>
					<div className="clearfix"></div>
					<Link to="/New-Tale" className="btn-large">
						CREATE A PET TALE
					</Link>
				</div>
			</div>
			<div className="clearfix"></div>
			<br />
			<br />
			{fetching ? <Loading /> : <HomePage data={data} setQ={setQ} />}
		</div>
	);
};

export default Page;
