import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import M from 'materialize-css';
import Quill from 'quill';
import { appendScript, removeScript, appendLink, removeLink } from '../../utils/scripts';
import Axios from 'axios';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useDrag, useDrop } from 'react-dnd';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const RECAPTCHA_KEY = '6LcpWPUZAAAAAPLM_INfKM65Qs0jMIW-lsJ1x17o';
// const RECAPTCHA_SECRET = '6LcpWPUZAAAAAKlRDYuRU0x-9a3E7CRKJjkqSfyZ';

const ItemTypes = {
   CARD: 'card',
};

const style = {
   width: 400,
};

const ExportPage = () => {
   return (
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
         <Page />
      </GoogleReCaptchaProvider>
   );
};

const Page = () => {
   return (
      <div className="">
         <div style={{ backgroundColor: '#F1F1F1', paddingBottom: '50px' }}>
            <div className="row container center">
               <h1 style={{ margin: '0' }}>It’s never easy to say goodbye</h1>
               <div className="col s12 m12 l8 offset-l2">
                  <p>
                     Pet Tales is an online pet obituary and tribute gateway that is designed pet parents to honor a pet's life and share their story with others. By sharing a Pet Tale, you will not only embrace the memories of your pet but also create a permanent place to remember your faithful
                     companion that brought so much happiness. Please feel free to page through our memory album below to read some of the Pet Tales shared by others.
                  </p>
               </div>

               {/* <div className="clearfix"></div>
					<Link to="/login" className="btn-large">
						CREATE A PET TALE
					</Link> */}
            </div>
         </div>
         <div className="clearfix"></div>
         <br />
         <br />
         <div className="row container">
            <div className="col s12 m12 l8 offset-l2">
               <Form />
            </div>
         </div>

         <div className="clearfix"></div>
         <div className="row container cursors"></div>

         <div className="clearfix"></div>
         <br />
         <br />
      </div>
   );
};

const formDefault = {
   name: '',
   slug: '',
   body: '',
   dob: '',
   dop: '',
   email: '',
   facebook: '',
   instagram: '',
   twitter: '',
   images: [],
};

const Form = () => {
   const { executeRecaptcha } = useGoogleReCaptcha();
   const token = executeRecaptcha('login_page');

   const [form, setForm] = React.useState(formDefault);

   const { register, handleSubmit, getValues, setValue, reset } = useForm({ defaultValues: form });

   const [scriptsLoaded, setScriptsLoaded] = React.useState([]);
   const [body, setBody] = React.useState('');
   const [images, setImages] = useState([]);
   const [dob, setDob] = useState('');
   const [dop, setDop] = useState('');

   const history = useHistory();

   useEffect(() => {
      const yearNow = new Date().getFullYear();

      const options = {
         format: 'mm/dd/yyyy',
         yearRange: [yearNow - 120, yearNow + 1],
         container: document.getElementById('calContainer'),
      };
      const elems = document.querySelectorAll('.datepicker');
      M.Datepicker.init(elems, options);

      appendScript('//cdn.quilljs.com/1.0.0/quill.min.js', (e) => {
         console.log('scriptsloaded');
         const s = scriptsLoaded.concat(['quill']);
         setScriptsLoaded(s);
      });
      appendLink('//cdn.quilljs.com/1.0.0/quill.snow.css');
      return () => {
         removeScript('//cdn.quilljs.com/1.0.0/quill.min.js');
         removeLink('//cdn.quilljs.com/1.0.0/quill.snow.css');
      };
   }, []);

   useEffect(() => {
      if (scriptsLoaded.length === 1) {
         const fullToolbarOptions = [[{ header: [1, 2, 3, false] }], ['bold', 'italic'], ['clean'], [{ list: 'ordered' }, { list: 'bullet' }]];

         let Editor = new Quill('#editor', {
            modules: {
               // table: true,
               toolbar: {
                  container: fullToolbarOptions,
               },
            },
            theme: 'snow',
         });

         Editor.root.innerHTML = form.body;

         Editor.on('text-change', (e, source) => {
            setBody(Editor.root.innerHTML);
            setValue('body', Editor.root.innerHTML);
         });
      }
   }, [scriptsLoaded]);

   const slugify = (string) => {
      var a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
      var b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
      var p = new RegExp(a.split('').join('|'), 'g');

      return string
         .toString()
         .toLowerCase()
         .replace(/\s+/g, '-') // Replace spaces with -
         .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
         .replace(/&/g, '-and-') // Replace & with 'and'
         .replace(/[^\w\-]+/g, '') // Remove all non-word characters
         .replace(/\-\-+/g, '-') // Replace multiple - with single -
         .replace(/^-+/, '') // Trim - from start of text
         .replace(/-+$/, ''); // Trim - from end of text
   };

   const onImageUpload = (e) => {
      const file = e.target.files[0];

      if (file) {
         const fileTypes = ['jpg', 'jpeg', 'gif', 'png'];
         const ext = file.name.split('.').pop().toLowerCase();
         const isAccepted = fileTypes.indexOf(ext) > -1;

         if (isAccepted) {
            e.target.value = '';

            const fd = new FormData();
            fd.append('image', file, file.name);

            let url = window.location.href;
            let arr = url.split('/');
            let arr2 = arr[2].split(':');
            const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

            Axios({
               method: 'POST',
               url: URL + 'tales.cfc?method=upload',
               data: fd,
               headers: {
                  'Content-Type': 'application/json',
               },
            })
               .then((r) => {
                  setImages([...images, { id: images.length + 1, name: r.data.file }]);
               })
               .catch((e) => {
                  console.log({ e });
               })
               .finally((e) => {});
         }
      }
   };

   const onSubmit = (values, z) => {
      values.images = images;
      values.slug = slugify(values.name);
      values.body = body;
      values.dob = dob;
      values.dop = dop;
      token
         .then((t) => {
            values.token = t;
            if (values)
               Axios({
                  method: 'POST',
                  url: `${URL}tales.cfc?method=save`,
                  headers: {
                     'Content-Type': 'application/json',
                  },
                  data: values,
               })
                  .then((res) => res.status === 200 && res.data)
                  .then((data) => {
                     data.success && M.toast({ html: 'Thanks, tale posted!' });
                     data.success && history.push(`/Pet-Tales/${values.slug}/${moment(values.dop).add(1, 'days').format('YYYYMMDD')}`);
                     !data.success && M.toast({ html: 'Unable to create tale, please try again.' });
                  })
                  .catch((e) => console.log(e));
         })
         .catch((e) => M.toast({ html: 'Captcha failed, please try again' }));
   };

   const ImageListItem = (props) => {
      console.log({ props });

      return <div className="col s12">row</div>;
   };

   const ListItem = (x) => {
      return <div className="col s12">{x.name}</div>;
   };

   const handleReorder = (p) => {
      console.log('reordering', { p });
      setImages(p);
   };

   return (
      <div className="row">
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col s12 m8 offset-m2">
               <div className="row">
                  <input type="hidden" name="slug" id="slug" ref={register()} />
                  <div className="input-field col s12">
                     <input type="text" name="name" id="name" ref={register()} />
                     <label htmlFor="name">Name</label>
                  </div>
               </div>

               <div className="row">
                  <div className="input-field- col s12">
                     <label className="active" htmlFor="body">
                        Details
                     </label>
                     <div id="editor"></div>
                     {/* <textarea name="body" ref={register()}></textarea> */}
                  </div>
               </div>

               <div className="row">
                  <div className="col s12" id="calContainer"></div>
                  <div className="input-field col s12 m6">
                     <input type="hidden" name="dob" id="dob" ref={register()} />
                     <DatePicker
                        // placeholderText="Date of Birth"
                        selected={dob}
                        onChange={(date) => setDob(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onCalendarOpen={(x) => {
                           const selects = document.querySelectorAll('.react-datepicker__header__dropdown--select select');
                           console.log(selects[1]);

                           selects.forEach((el, i) => {
                              el.classList.add('browser-default');
                              el.style.height = '25px';
                           });
                        }}
                     />
                     <label className="active" htmlFor="dob">
                        Date of Birth
                     </label>
                  </div>
                  <div className="input-field col s12 m6">
                     <input type="hidden" name="dop" id="dop" ref={register()} />
                     <DatePicker
                        // placeholderText="Date of Passing"
                        selected={dop}
                        onChange={(date) => setDop(date)}
                        onSelect={(date) => {
                           console.log(date);
                           setValue('dop', date);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onCalendarOpen={(x) => {
                           const selects = document.querySelectorAll('.react-datepicker__header__dropdown--select select');
                           console.log(selects[1]);

                           selects.forEach((el, i) => {
                              el.classList.add('browser-default');
                              el.style.height = '25px';
                           });
                        }}
                     />
                     <label className="active" htmlFor="dop">
                        Date of Passing
                     </label>
                  </div>
               </div>

               <div className="row">
                  <div className="input-field col s12 m6">
                     <input type="text" name="email" id="email" ref={register()} />
                     <label htmlFor="email">Email</label>
                  </div>
                  <div className="input-field col s12 m6">
                     <input type="text" name="facebook" id="facebook" ref={register()} />
                     <label htmlFor="facebook">Facebook</label>
                  </div>
               </div>

               <div className="row">
                  <div className="input-field col s12 m6">
                     <input type="text" name="twitter" id="twitter" ref={register()} />
                     <label htmlFor="twitter">Twitter</label>
                  </div>
                  <div className="input-field col s12 m6">
                     <input type="text" name="instagram" id="instagram" ref={register()} />
                     <label htmlFor="instagram">Instagram</label>
                  </div>
               </div>

               <div className="row center-align">
                  {images.length > 0 && <small>Drag to reorder</small>}
                  <DndProvider backend={HTML5Backend}>
                     <ReOrder items={images} updateCards={handleReorder} />
                  </DndProvider>
               </div>

               <div className="row inputs">
                  <div className="file-field input-field col s12">
                     <div className="btn">
                        <span>Image</span>
                        <input name="file" accept="image/*" id="file" type="file" onChange={onImageUpload} />
                     </div>
                     <div className="file-path-wrapper">
                        <input className="file-path validate" type="text" />
                     </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col s12 center-align">
                     <button type="submit" name="post" className="btn green white-text">
                        Submit
                     </button>
                  </div>
               </div>
            </div>
         </form>
      </div>
   );
};

const ReOrder = ({ items, updateCards }) => {
   const [cards, setCards] = useState(items);

   useEffect(() => setCards(items), [items]);

   const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
         const dragCard = cards[dragIndex];
         const _cards = update(cards, {
            $splice: [
               [dragIndex, 1],
               [hoverIndex, 0, dragCard],
            ],
         });
         setCards(_cards);
         updateCards(_cards);
      },
      [cards]
   );

   const renderCard = (card, index) => {
      return <Card key={card.id} index={index} id={card.id} name={card.name} moveCard={moveCard} />;
   };

   console.log({ cards });
   return (
      <>
         <div style={{ ...style, margin: '0 auto' }} className="collection">
            {cards.map((card, i) => renderCard(card, i))}
         </div>
      </>
   );
};

const Card = ({ id, name, index, moveCard }) => {
   const ref = useRef(null);
   const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      hover(item, monitor) {
         if (!ref.current) {
            return;
         }
         const dragIndex = item.index;
         const hoverIndex = index;
         // Don't replace items with themselves
         if (dragIndex === hoverIndex) {
            return;
         }
         // Determine rectangle on screen
         const hoverBoundingRect = ref.current?.getBoundingClientRect();
         // Get vertical middle
         const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
         // Determine mouse position
         const clientOffset = monitor.getClientOffset();
         // Get pixels to the top
         const hoverClientY = clientOffset.y - hoverBoundingRect.top;
         // Only perform the move when the mouse has crossed half of the items height
         // When dragging downwards, only move when the cursor is below 50%
         // When dragging upwards, only move when the cursor is above 50%
         // Dragging downwards
         if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
         }
         // Dragging upwards
         if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
         }
         // Time to actually perform the action
         moveCard(dragIndex, hoverIndex);
         // Note: we're mutating the monitor item here!
         // Generally it's better to avoid mutations,
         // but it's good here for the sake of performance
         // to avoid expensive index searches.
         item.index = hoverIndex;
      },
   });
   const [{ isDragging }, drag] = useDrag({
      item: { type: ItemTypes.CARD, id, index },
      collect: (monitor) => ({
         isDragging: monitor.isDragging(),
      }),
   });
   const opacity = isDragging ? 0 : 1;
   drag(drop(ref));
   return (
      <div ref={ref} style={{ ...style, opacity }}>
         <div className="collection-item">
            <img src={name} style={{ height: '50px' }} />
            <i style={{ paddingLeft: '10px', lineHeight: '50px' }} className="material-icons left">
               drag_handle
            </i>
         </div>
      </div>
   );
};

export default ExportPage;
