import React from "react";
import { Link } from "react-router-dom";
import M from "materialize-css";

// const topStyle = {};

export default class Home extends React.Component {
	componentDidMount() {
		M.Sidenav.init(document.getElementById("mobile-nav"));
	}

	render() {
		return (
			<div className="" style={{ backgroundColor: "#0f75bd" }}>
				<div
					className="container center white-text"
					style={{ position: "relative" }}
				>
					<footer className="row" style={{ padding: "5px 0", margin: "0" }}>
						<ul className="hide-on-med-and-down">
							<li>
								<Link className="" to="/About-Us">
									About Us
								</Link>
							</li>
							<li>
								<Link className="" to="/Why-Choose-Us">
									Why Choose Us
								</Link>
							</li>
							<li>
								<Link className="" to="/Our-Services">
									Our Services
								</Link>
							</li>
							<li>
								<Link className="" to="/Pet-Tales">
									Pet Tales
								</Link>
							</li>
							<li>
								<Link className="" to="/FAQs">
									FAQs
								</Link>
							</li>
							<li>
								<Link className="" to="/Contact-Us">
									Contact Us
								</Link>
							</li>
							<li className="ssIcon white-text">
								<a
									href="https://www.facebook.com/foxandweekspetsatpeace"
									target="_blank"
									rel="noopener noreferrer"
									className="fbIcon white-text"
								>
									<i class="fab fa-facebook-f"></i>
								</a>
							</li>
						</ul>
						<div className="col s12 m6 l4 offset-l4 center hide-on-med-and-down">
							<br />
							7200 Hodgson Memorial Drive <br />
							Savannah, GA 31406 <br />
							(912) 800-7005
							<br />
							<br />
						</div>
						
						<div className="clearfix"></div>
						<p
							style={{
								textTransform: "uppercase",
								fontSize: "14px",
								marginTop: "25px",
							}}
						>
							&copy; 2020 Fox & Weeks | Crafted and Hosted by{" "}
							<a
								href="https://thesabresolution.com/"
								rel="noopener noreferrer"
								target="_blank"
								className="white-text"
							>
								Sabre Creative
							</a>
						</p>
					</footer>
				</div>
			</div>
		);
	}
}
